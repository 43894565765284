<template>
  <section>
    <SelectOrderType />
    <RequirementsOrder />
    <ContinueOrderBtn closeDialog class="mt-2" />
  </section>
</template>
<script>
import {
  orderTypeStoreGetters,
  orderTypeName,
} from "@/store/modules/orderType/constNames";
import { userInfoStoreGetters } from "@/store/modules/userInfo/constNames";
export default {
  name: "SettingOrder",
  props: {
    settingOrder: { type: Boolean, default: false },
  },
  components: {
    ContinueOrderBtn: () => import("./buttons/ContinueOrderBtn"),
    RequirementsOrder: () => import("../RequirementsOrder/RequirementsOrder"),
    SelectOrderType: () => import("./SelectOrderType"),
  },
  computed: {
    disableFinishOrderBtn() {
      const { qr } = this.$store.getters[orderTypeStoreGetters.GET_ORDER_LOCAL];
      return (
        !this.IHaveSelectedAnOrderType ||
        (this.orderTypeIsToHome && !this.IHaveSelectedAnAddress) ||
        (!this.orderTypeIsToHomeOrToPickUp && !qr)
      );
    },
    IHaveSelectedAnOrderType() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
    },
    IHaveSelectedAnAddress() {
      return this.$store.getters[orderTypeStoreGetters.GET_ADDRESS];
    },
    orderTypeIsToHomeOrToPickUp() {
      return this.orderTypeIsToHome || this.orderTypeIsToPickUp;
    },
    orderTypeIsToHome() {
      return this.IHaveSelectedAnOrderType === orderTypeName.AT_HOME;
    },
    orderTypeIsToPickUp() {
      return this.IHaveSelectedAnOrderType === orderTypeName.TO_PICK_UP;
    },
    userPhoneConfigured() {
      return this.$store.getters[userInfoStoreGetters.GET_USER_PHONE];
    },
  },
};
</script>
